// *******************************************************************************
// 0.0 - GENERAL FUNCTIONS
// *******************************************************************************

// FASTCLICK
// Causes issues with form checkbox for terms (user has to double tap in case fastclick is enabled)
$(function () {
	FastClick.attach(document.body);
});



// *******************************************************************************
// 0.1 - ATTACH EVENT (usefull for dynamically adeed elements)
// *******************************************************************************

// Attach Events
function attachEvent(selector, event, handler) {
	document.addEventListener(event, (ev) => {
		let { target } = ev;
		for (; target && target !== document; target = target.parentNode) {
			if (target.matches(selector)) {
				try {
					handler.call(target, ev);
				} catch (e) {
					console.error(e);
				}
				break;
			}
		}
	}, false);
}



// *******************************************************************************
// 0.2 - DEBOUNCE FUNCTION
// *******************************************************************************

function debounce(func, wait, immediate) {
	let timeout;
	return function (...args) {
		const context = this;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}



// *******************************************************************************
// 0.3 - Viewport calculation (prevents page jumping)
// *******************************************************************************

function viewportUnitsCalc() {
	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	let vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function viewportUnitsInit() {
	var viewportUnitsCalcDebounce = debounce(function () {
		viewportUnitsCalc();
	}, 100);
	window.addEventListener('load', viewportUnitsCalcDebounce);
	window.addEventListener('resize', viewportUnitsCalcDebounce);
}

viewportUnitsInit();



// *******************************************************************************
// 0.4 - Shortcut functions
// *******************************************************************************

function closeActivePopup() {

	let popupWindowNode = document.querySelectorAll('.popup.is-active')[0];
	if (popupWindowNode) {
		popupWindowNode.classList.remove('is-active');
	}

	let popupCloseNode = document.querySelectorAll('.popup-close.is-active')[0];
	if (popupCloseNode) {
		popupCloseNode.parentNode.removeChild(popupCloseNode);
	}

}

// ##################################################

function closeSupportSubmenu() {

	// Close submenu
	let supportMenu = document.querySelectorAll('.popmenu.is-active')[0];
	if (supportMenu) { supportMenu.classList.remove('is-active'); }

	let supportMenuTrigger = document.querySelectorAll('.nav-support')[0];
	if (supportMenuTrigger) { supportMenuTrigger.classList.remove('is-active'); }

}

// ##################################################

function closeSubmenuLine(direction) {

	// Possible values are: "normal-up" and "normal-down"

	const submenuItemActive = document.querySelectorAll('.menu-secondary-item.is-active')[0];
	const submenuArrowElem = submenuItemActive.querySelectorAll('.submenu-line-effect polyline')[0];

	// Default value is to close the line from bottom to normal
	if(!direction) { direction = "normal-down"; }

	if (submenuItemActive) {
		menuArrowPosition({
			element: submenuArrowElem,
			position: direction
		})
	}
}

// ##################################################

function closeSubmenu() {

	// Remove active class from active Popup window and active submenu link
	const submenuActive = document.querySelectorAll('.popup--submenu.is-active')[0];
	if (submenuActive) { submenuActive.classList.remove('is-active'); }

	const submenuItemActive = document.querySelectorAll('.menu-secondary-item.is-active')[0];
	if (submenuItemActive) {
		closeSubmenuLine();
		submenuItemActive.classList.remove('is-active');
	}

}

// ##################################################

function closeSubmenuLinks() {

	let submenuItemActive = document.querySelectorAll('.menu-secondary-item.is-active')[0];
	if (submenuItemActive) {

		if (document.body.classList.contains('show-subpage')) {
			closeSubmenuLine("normal-up");
		}
		else {
			closeSubmenuLine();
		}
		submenuItemActive.classList.remove('is-active');
	}

}

// ##################################################

function toggleScreensaver(control) {

	let screensaverElem = document.querySelectorAll('.screensaver')[0],
	screensaverVideo = screensaverElem.querySelectorAll('video')[0],
	screensaverTitle = screensaverElem.querySelectorAll('.screensaver-title')[0],
	screensaverTitleShowAtTime = 4.75, // When to show the title text
	screensaverVideoTimeOffset = 0.5; // Duration of the title text animation

	// SHOW the screensaver and sync the title text with correct time in the video
	if (screensaverElem && control == "show" && !document.body.classList.contains('screensaver-active') ) {
		document.body.classList.add('screensaver-active');

		closeActivePopup();

		// Go back to initial position (timeout 1s because of the video fade-in animation)
		setTimeout(function () {
			closeSubmenu();
			closeSubmenuLinks();
			closeSupportSubmenu();
			document.body.classList.remove('show-subpage');
			document.body.classList.remove('page--main');
			document.body.classList.remove('is-open-menu');
			document.body.classList.add('page--homepage');
		}, 1000);

		screensaverVideo.play();
		screensaverVideo.currentTime = 0;

		// Looping through video
		screensaverVideo.ontimeupdate = function () {

			// Show the title text when we're inside the correct time frame of the video
			if (screensaverVideo.currentTime > (screensaverTitleShowAtTime - screensaverVideoTimeOffset) && screensaverVideo.currentTime < (screensaverVideo.duration - screensaverVideoTimeOffset)) {

				if (!screensaverTitle.classList.contains('is-active')) {
					screensaverTitle.classList.add('is-active');
				}

			}

			// Fade IN and fade OUT of video, when it's cloe the end!
			if (screensaverVideo.duration < (screensaverVideo.currentTime + screensaverVideoTimeOffset * 2)) {
				//console.log('RESET!');
				screensaverVideo.classList.add('fade-out');
				if (screensaverTitle.classList.contains('is-active')) { screensaverTitle.classList.remove('is-active'); }
				setTimeout(function () {
					screensaverVideo.pause();
					screensaverVideo.currentTime = 0;
					screensaverVideo.play();
					screensaverVideo.classList.remove('fade-out');
				}, 333)
			}

		};

	}

	// HIDE the screensaver
	if (screensaverElem && control == "hide") {
		document.body.classList.remove('screensaver-active');
		setTimeout(function () {
			screensaverVideo.pause();
			screensaverVideo.currentTime = 0;
			screensaverTitle.classList.remove('is-active');
		}, 1000);
	}

}

// ##################################################

function showPageMain() {
	document.body.classList.remove('page--homepage');
	document.body.classList.add('page--main');
}

// ##################################################

function showPageHome() {
	document.body.classList.remove('show-subpage', 'page--main');
	document.body.classList.add('page--homepage');
}


// *******************************************************************************
// 1.0 - POPUP WINDOW OPEN
// *******************************************************************************

const popupPin = document.querySelectorAll('.popup-pin');

popupPin.forEach(function (thisPin, index) {
	thisPin.addEventListener('click', function (ev) {

		// Close all open popup windows and clear close icons
		closeActivePopup();

		if (!document.body.classList.contains('show-subpage')) {
			closeSubmenuLinks();
		}

		// ##################################################

		let targetPopup = thisPin.getAttribute('data-popup');
		targetPopup = document.querySelectorAll('.popup[data-popup="' + targetPopup + '"]');

		// ##################################################

		let pinPosition = thisPin.getBoundingClientRect();

		// Create close icon
		let popupCloseElemColor = thisPin.getAttribute('data-color');

		let popupCloseElem = document.createElement('div');

		if (popupCloseElemColor == "white") {
			popupCloseElem.classList.add('popup-close', 'popup-close--overlay', 'popup-close--white');
		} else {
			popupCloseElem.classList.add('popup-close', 'popup-close--overlay', 'popup-close--dark');
		}
		document.body.appendChild(popupCloseElem);

		// ##################################################

		//const popupCloseElem = document.querySelectorAll('.popup-close');
		let pinDimensions = popupCloseElem.getBoundingClientRect();

		// Position close icon

		let pinClosePosition = {
			x: Math.ceil(pinPosition.x + (pinPosition.width / 2) - (pinDimensions.width / 2)),
			y: Math.ceil(pinPosition.y + (pinPosition.height / 2) - (pinDimensions.height / 2))
		};

		popupCloseElem.style.left = pinClosePosition.x + "px";
		popupCloseElem.style.top = pinClosePosition.y + "px";

		// ##################################################

		// Positioning of the Popup window
		let popupDirection = {
			x: thisPin.getAttribute('data-direction-x'),
			y: thisPin.getAttribute('data-direction-y')
		};

		let popupNewPosition = {};
		let popupNewPositionOffset = 10;
		let targetPopupDimensions = targetPopup[0].getBoundingClientRect();

		// Check for direction is set correctly
		switch (popupDirection.x) {
			case "left":
				// LEFT TOP
				if (popupDirection.y === "top") {
					popupNewPosition.x = Math.ceil(pinPosition.x + popupNewPositionOffset);
					popupNewPosition.y = Math.ceil(pinPosition.y + popupNewPositionOffset);
				}
				// LEFT BOTTOM
				if (popupDirection.y === "bottom") {
					popupNewPosition.x = Math.ceil(pinPosition.x + popupNewPositionOffset);
					popupNewPosition.y = Math.ceil(pinPosition.y + pinPosition.height - targetPopupDimensions.height - popupNewPositionOffset);
				}
				break;
			case "right":
				// RIGHT TOP
				if (popupDirection.y === "top") {
					popupNewPosition.x = Math.ceil(pinPosition.x + pinPosition.width - targetPopupDimensions.width - popupNewPositionOffset);
					popupNewPosition.y = Math.ceil(pinPosition.y + popupNewPositionOffset);
				}
				// RIGHT BOTTOM
				if (popupDirection.y === "bottom") {
					popupNewPosition.x = Math.ceil(pinPosition.x + pinPosition.width - targetPopupDimensions.width - popupNewPositionOffset);
					popupNewPosition.y = Math.ceil(pinPosition.y + pinPosition.height - targetPopupDimensions.height - popupNewPositionOffset);
				}
				break;
		}

		// Set the position
		targetPopup[0].style.left = popupNewPosition.x + "px";
		targetPopup[0].style.top = popupNewPosition.y + "px";

		// ##################################################

		// Opening the Popup for this pin
		setTimeout(function () {
			targetPopup[0].classList.add('is-active');
			popupCloseElem.classList.add('is-active');
		}, 200);

		// ##################################################

	});
});



// *******************************************************************************
// 1.1 - POPUP WINDOW CLOSE
// *******************************************************************************

attachEvent('.popup-close--overlay', 'click', (ev) => {

	closeSupportSubmenu();

	// Do not close the submenu if we're on the subpage!
	if(!document.body.classList.contains('show-subpage')) {
		closeSubmenu();
	}

	// ##################################################

	// Remove active class from active Popup window
	document.querySelectorAll('.popup--overlay.is-active')[0].classList.remove('is-active');

	// Remove from DOM
	ev.target.parentNode.removeChild(ev.target);

});



// *******************************************************************************
// 2.0 - MAIN MENU TOGGLING
// *******************************************************************************

attachEvent('.menu-hamburger', 'click', (ev) => {
	document.body.classList.toggle('is-open-menu');
});


attachEvent('.menu-hamburger-label', 'click', (ev) => {
	document.body.classList.toggle('is-open-menu');
});


// Back button toggling
attachEvent('.back-button', 'click', (ev) => {

	if (document.body.classList.contains('show-subpage')) {

		// Close submenu
		closeSupportSubmenu();

		// Remove active for submenu item
		closeSubmenuLinks();

		// Remove active class from active Popup window
		closeActivePopup();

		document.body.classList.remove('show-subpage');

	} else {

		if ( document.body.classList.contains('page--main') ) {

			// Close submenu
			closeSupportSubmenu();

			// Remove active for submenu item
			closeSubmenuLinks();

			// Remove active class from active Popup window
			closeActivePopup();

			showPageHome();

		}

	}


});



// *******************************************************************************
// 3.0 - SUBMENU IMAGE SHOW/HIDE
// *******************************************************************************

const supportMenuImageLink = document.querySelectorAll('.popmenu-col-item--image');

supportMenuImageLink.forEach(function (thisLink, index) {
	thisLink.addEventListener('click', function (ev) {

		// Close all open popup windows and clear close icons
		let submenuParent = thisLink.closest('.popmenu');
		let submenuImageContainer = submenuParent.querySelectorAll('.popmenu-image')[0];

		submenuImageContainer.classList.toggle('is-active');

		// ##################################################

		// Add active the current link
		if (thisLink.classList.contains('is-active') ) {
			// Clear class for all other links
			supportMenuImageLink.forEach(function (elem, index) { elem.classList.remove('is-active'); });
		} else {
			// Clear class for all other links
			supportMenuImageLink.forEach(function (elem, index) { elem.classList.remove('is-active'); });
			thisLink.classList.add('is-active');
		}

	});
});

// OPEN SUPPORT SUBMENU
attachEvent('.nav-support', 'click', (ev) => {

	closeActivePopup();
	closeSubmenu();

	document.querySelectorAll('.popmenu')[0].classList.add('is-active');
	document.querySelectorAll('.nav-support')[0].classList.add('is-active');
});

// CLOSE SUPPORT SUBMENU
attachEvent('.popmenu-close', 'click', (ev) => {
	closeSupportSubmenu();
});



// *******************************************************************************
// 3.0 - SUBMENU IMAGE SHOW/HIDE
// *******************************************************************************

// OPEN SUBMENU LINK
const submenuItemLink = document.querySelectorAll('.menu-secondary-link');

submenuItemLink.forEach(function (thisItemLink, index) {
	thisItemLink.addEventListener('click', function (ev) {

		// Close all open popup windows and clear close icons
		let submenuItem = thisItemLink.closest('.menu-secondary-item');
		let submenuPopup = submenuItem.querySelector('.popup');
		let submenuSVGLine = submenuItem.querySelector('.submenu-line-effect polyline');

		// ##################################################

		closeActivePopup();
		closeSupportSubmenu();

		// ##################################################

		// CASE: Clicked on active submenu link
		if (submenuItem.classList.contains('is-active')) {

			if (document.body.classList.contains('show-subpage')) {

				closeSubmenuLinks(); // Clear active class for Submenu items LI
				menuArrowPosition({
					element: submenuSVGLine,
					position: "normal-down"
				})

			} else {
			// We're on subpage and current link is active

				menuArrowPosition({
					element: submenuSVGLine,
					position: "up"
				})

				// We go to subpage and close submenu popup
				let supportMenu = document.querySelectorAll('.popmenu.is-active')[0];
				if (supportMenu) {
					document.querySelectorAll('.nav-support')[0].classList.remove('is-active');
					supportMenu.classList.remove('is-active');
					submenuItemLink.forEach(function (elem, index) { elem.classList.remove('is-active'); });
				}

				document.querySelectorAll('.nav-support')[0].classList.remove('is-active');

			}

			document.body.classList.toggle('show-subpage'); // Toggle body class
			closeActivePopup(); // Remove active class from active Popup window


		// ##################################################
		// ##################################################

		} else {
		// CASE: Clicked on submenu that is not active


			if (document.body.classList.contains('show-subpage')) {

				menuArrowPosition({
					element: submenuSVGLine,
					position: "up"
				})

			} else {

				menuArrowPosition({
					element: submenuSVGLine,
					position: "down"
				})

				submenuPopup.classList.add('is-active');

			}

			closeSubmenuLinks(); // Clear active class for Submenu items LI

			// Clear active class for Submenu links A
			submenuItem.classList.add('is-active');

		}

		// ##################################################

	});
});



// CLOSE SUBMENU LINK
attachEvent('.popup--submenu .popup-close', 'click', (ev) => {

	// Remove active class from active Popup window and active submenu link
	closeSubmenu();

});





// *******************************************************************************
// 4.0 - SVG PIE CHARTS
// *******************************************************************************

const donutPieCharts = document.querySelectorAll('.donut-pie-chart');

donutPieCharts.forEach(function (thisPieChart, index) {

	// Close all open popup windows and clear close icons
	let thisPercent = thisPieChart.getAttribute('data-percent');
	let thisPieCircle = thisPieChart.querySelector('.donut-pie-circle');
	let thisPiePercentText = thisPieChart.querySelector('.donut-pie-percent');

	let thisPieCircleOldValue = parseInt( getComputedStyle(thisPieCircle).strokeDashoffset );

	let thisPieCircleNewValue = thisPieCircleOldValue - (thisPieCircleOldValue / 100 * thisPercent);

	thisPieCircle.style.strokeDashoffset = thisPieCircleNewValue;
	thisPiePercentText.innerHTML = thisPercent;

});





// *******************************************************************************
// 5.0 - SCREENSAVER VIDEO
// *******************************************************************************

attachEvent('.screensaver', 'click', (ev) => {
	toggleScreensaver("hide");
});

//attachEvent('.header-logo', 'click', (ev) => {
//	toggleScreensaver("show");
//});

(function () {

	const idleDurationSecs = 300;
	let idleTimeout;

	const resetIdleTimeout = function () {

		// Clears the existing timeout
		if (idleTimeout) clearTimeout(idleTimeout);

		// Set a new idle timeout to load the redirectUrl after idleDurationSecs
		idleTimeout = setTimeout(() => toggleScreensaver("show"), idleDurationSecs * 1000);
	};

	// Init on page load
	resetIdleTimeout();

	// Reset the idle timeout on any of the events listed below
	['click', 'touchstart', 'mousemove'].forEach(evt =>
		document.addEventListener(evt, resetIdleTimeout, false)
	);

})();





// *******************************************************************************
// 6.0 - SUBMENU LINE ANIMATION
// *******************************************************************************

function menuArrowPosition(params) {

	let lineOffset = 15;
	    lineNormal = '0,0 65,0 130,0',
	    lineTop = '0,0 65,-' + lineOffset + ' 130,0', //30.6
	    lineBottom = '0,0 65,' + lineOffset + ' 130,0', //31.6
	    lineMoveTo = [];

	// Move the line up and move up to top
	if(params.position == "up") { lineMoveTo = [lineNormal, lineTop] }

	// Move the line down
	if (params.position == "down") { lineMoveTo = [lineNormal, lineBottom] }

	// Return the line to initial position
	if (params.position == "normal-up") { lineMoveTo = [lineTop, lineNormal] }
	if (params.position == "normal-down") { lineMoveTo = [lineBottom, lineNormal] }

	// ##################################################

	anime({
		targets: params.element,
		points: [
			{
				value: [lineMoveTo]
			}
		],
		easing: 'linear',
		duration: 250,
		loop: false
		// update: function (anim) {
		// 	console.log("update");
		// },
		// begin: function () {
		// 	console.log("begin");
		// },
		// run: function () {
		// 	console.log("run");
		// },
		// complete: function () {
		// 	console.log("complete");
		// }
	});

}

//const submenuArrowElem = document.querySelectorAll('.submenu-line-effect')[2];
//menuArrowPosition({
//	element: submenuArrowElem.querySelector('polyline'),
//	position: "down"
//})







// *******************************************************************************
// X.X - DOCUMENT LOADED
// *******************************************************************************

document.addEventListener('DOMContentLoaded', function (ev) {
	//toggleScreensaver('show');
});





// TEMP - REMOVE
attachEvent('.home-icon', 'click', (ev) => {
	showPageMain();
});



// *******************************************************************************
// X.X - PARTICLE JS CONFIGURATION
// *******************************************************************************


particlesJS('bottom-right-particle',

{
	"particles": {
	  "number": {
		"value": 1400,
		"density": {
		  "enable": true,
		  "value_area": 400
		}
	  },
	  "color": {
		"value": ["#FE7C53","#f3824a","#fb9a55","#ff624f","#e95d39"]
	  },
	  "shape": {
		"type": "circle",
		"stroke": {
		  "width": 0,
		  "color": "#000000"
		},
		"polygon": {
		  "nb_sides": 5
		},
		"image": {
		  "src": "img/github.svg",
		  "width": 100,
		  "height": 100
		}
	  },
	  "opacity": {
		"value": 1,
		"random": true,
		"anim": {
		  "enable": true,
		  "speed": 1,
		  "opacity_min": 0,
		  "sync": false
		}
	  },
	  "size": {
		"value": 3,
		"random": true,
		"anim": {
		  "enable": false,
		  "speed": 4,
		  "size_min": 0.3,
		  "sync": false
		}
	  },
	  "line_linked": {
		"enable": false,
		"distance": 150,
		"color": "#ffffff",
		"opacity": 0.4,
		"width": 1
	  },
	  "move": {
		"enable": true,
		"speed": 1,
		"direction": "none",
		"random": true,
		"straight": false,
		"out_mode": "out",
		"bounce": false,
		"attract": {
		  "enable": false,
		  "rotateX": 400,
		  "rotateY": 400
		}
	  }
	},
	"interactivity": {
	  "detect_on": "canvas",
	  "events": {
		"onhover": {
		  "enable": false,
		  "mode": "bubble"
		},
		"onclick": {
		  "enable": false,
		},
		"resize": false,
	  },
	  "modes": {
		"grab": {
		  "distance": 400,
		  "line_linked": {
			"opacity": 1
		  }
		},
		"bubble": {
		  "distance": 250,
		  "size": 0,
		  "duration": 2,
		  "opacity": 0,
		  "speed": 3
		},
		"repulse": {
		  "distance": 400,
		  "duration": 0.4
		},
		"push": {
		  "particles_nb": 4
		},
		"remove": {
		  "particles_nb": 2
		}
	  }
	},
	"retina_detect": true
  }
);
